import React from 'react';
import {Marker, Tooltip} from 'react-leaflet';


import HotelTooltip from './MarkerTooltip';
import {hotelPin, locationPin, homePin} from './Pin';
const TOOLTIP_OFFSET = [-13, -50];

export function homeMarker({latitude, longitude}, label) { // eslint-disable-line react/prop-types
    return (<Marker
        key="home"
        position={{lat: latitude, lng: longitude}}
        icon={homePin()}>
        <Tooltip direction="top" offset={TOOLTIP_OFFSET}>
            <span>{label}</span>
        </Tooltip>
    </Marker>);
}

export function locationMarker(props, id) {
    const {name, latitude, longitude} = props; // eslint-disable-line react/prop-types
    return (<Marker
        key={id}
        position={{lat: latitude, lng: longitude}}
        icon={locationPin()}>
        <Tooltip direction="top" offset={TOOLTIP_OFFSET}>
            <span>{name}</span>
        </Tooltip>
    </Marker>);
}


export function hotelMarker(props, clickHandler) {
    const {id, latitude, longitude}  = props; // eslint-disable-line react/prop-types
    return (
        <Marker
            id={id}
            eventHandlers={{
                click: (el) => {
                    if (clickHandler) {
                        clickHandler(el);

                    }
                },
            }}
            key={id}
            position={{lat: latitude, lng: longitude}}
            icon={hotelPin(props)}>
            <Tooltip direction="top" offset={TOOLTIP_OFFSET}>
                <HotelTooltip {...props} />
            </Tooltip>
        </Marker>);
}
