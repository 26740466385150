import {renderToString} from 'react-dom/server';
import React from 'react';
import classnames from 'classnames';
import gettext from 'airborne/gettext';
import {divIcon} from 'leaflet';

const ICON_SIZE = [26, 32];
const ICON_ANCHOR = [13, 32];

const altHotelLabel = () => {
    return renderToString(
        <div style={{
            'top': '-33px',
            'left': '-5px'
        }}
        className="map-view__label"
        >
            {gettext('Alternative Property')}
        </div>
    );
};

export function hotelPin(hotel) {
    const {
        available,
        'client_preferred': clientPref,
        'tmc_preferred': agentPref,
        isAlternative
    } = hotel;
    const tpOut = Boolean(available && available['out_of_policy']);
    const className = classnames('map-pin3', {
        'map-pin--agency-preferred': agentPref && !tpOut,
        'map-pin--corporate-preferred': clientPref && !tpOut || isAlternative,
        'map-pin--standard': !agentPref && !clientPref && Boolean(available && available['is_available']) && !tpOut,
        'map-pin--out-of-policy': tpOut
    });


    return divIcon({
        className,
        iconSize: ICON_SIZE,
        iconAnchor: ICON_ANCHOR,
        html: isAlternative ? altHotelLabel() : ''
    });
}

export function locationPin() {
    return divIcon({
        className: 'map-pin3 map-pin--cl',
        iconSize: ICON_SIZE,
        iconAnchor: ICON_ANCHOR
    });
}

export function homePin() {
    return divIcon({
        className: 'map-pin3 map-pin--home',
        iconSize: ICON_SIZE,
        iconAnchor: ICON_ANCHOR
    });
}
