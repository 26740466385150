import store from 'airborne/store';
import {changeFilters, selectHotel} from 'airborne/store/modules/search_hotels/actions/filters';
import {toList} from 'airborne/store/modules/search_hotels/actions';

export function toHotelRates({id, name}) {
    store.dispatch(changeFilters({value: {name}, errors: null}));
    store.dispatch(selectHotel(id, 'rates'));
    store.dispatch(toList());

    return {type: 'NOOP'};
}
