import find from 'lodash/find';
import settings from 'airborne/settings';
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import 'react-leaflet-markercluster/dist/styles.min.css';

import gettext from 'airborne/gettext';

import MultiSearchContext from 'airborne/search2/MultiSearchContext';
import Filters from 'airborne/search_cars/Filters';
import Button from 'midoffice/components/Button';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import {GoogleLayer} from 'react-leaflet-google-v2';

import MarkerPopover from './MarkerPopover';

import {clearFilters} from 'airborne/store/modules/search_cars/actions/rates';

import {
    getFilteredRates,
    getFilters,
    isRatesLoading
} from 'airborne/store/modules/search_cars/selectors';
import {filterRates} from 'airborne/store/modules/search_cars/actions/rates';
import {findCenter, findCommonCenter, getCarsCoordinates, getTotal} from 'airborne/search_cars/map/helpers';
import isEmpty from 'lodash/isEmpty';
import CarMarker from 'airborne/search_cars/map/CarMarker';
import uniqBy from 'lodash/uniqBy';
import QuerySize from 'airborne/search2/map3/QuerySize';
import {MapContainer} from 'react-leaflet';

const DEFAULT_MAP_ZOOM = 13;

const mapStateToProps = createSelector(
    [
        getFilteredRates,
        getCarsCoordinates,
        findCenter,
        findCommonCenter,
        getFilters,
        getFilteredRates,
        getTotal,
        isRatesLoading,
        (state)=>  state.dest.cars.value.pickUp,
    ],
    function (fullList, list, center, commonCenter, filters, carRates, total, loading, dest) {
        const {label} = dest;
        const nonfilteredList = uniqBy(fullList, 'vendorLocationCode');

        const showEmptyMap = list.length === 0 && nonfilteredList.length !== list.length;
        const showWarning = list.length !== 0 && nonfilteredList.length !== list.length;
        return {
            cars: list,
            showWarning,
            total,
            center: showEmptyMap ? commonCenter : center,
            carRates,
            home: showEmptyMap ? commonCenter : center,
            filters,
            homeLabel: label,
            ready: Boolean(center) || showEmptyMap,
            showEmptyMap,
            loading,
        };
    },
);


@connect(mapStateToProps)
class MapView extends React.Component {
    static propTypes = {
        cars: PropTypes.array.isRequired,
        total: PropTypes.number.isRequired,
        ready: PropTypes.bool,
        showWarning: PropTypes.bool,
        showEmptyMap: PropTypes.bool,
        loading: PropTypes.bool,
        center: PropTypes.shape({
            latitude: PropTypes.number,
            longitude: PropTypes.number,
            zoom: PropTypes.number,
        }),
        home: PropTypes.shape({
            latitude: PropTypes.number,
            longitude: PropTypes.number,
        }),
        homeLabel: PropTypes.string,
        toggleMap: PropTypes.func,
        onClose: PropTypes.func,
        filters: PropTypes.object,
        filterRates: PropTypes.func,
        carRates: PropTypes.array,
    };

    state = {
        selected: null,
    };

    componentDidMount() {
        document.body.classList.add('modal-open');
    }

    componentWillUnmount() {
        document.body.classList.remove('modal-open');
    }

    setFilter = ({vendorLocationCode}) => {
        const {toggleMap, filters, filterRates} = this.props;
        toggleMap();
        filterRates({
            value: {...filters, vendorLocationCode: vendorLocationCode}
        });
    }

    handleClick = (id)=> {
        this.setState({selected: id});
    };

    handleUnselect = ()=> {
        this.setState({selected: null});
    };

    renderSelected(cars, selected) {
        const rate = find(cars, {rateId: selected});
        if (!rate) {
            return null;
        }
        return (<MarkerPopover
            {...rate}
            onClick={this.setFilter}
            onClose={this.handleUnselect} />);
    }

    renderMarkers(cars) {
        return cars.map((el)=> <CarMarker key={el.id} {...el} clickHandler={this.handleClick} />);
    }

    renderMap() {
        const {selected} = this.state;
        const {cars, total, carRates, center, showWarning, showEmptyMap, toggleMap} = this.props;
        const {latitude, longitude, zoom} = center || {latitude: 0, longitude: 0};

        return (<div style={{width: '100%', height: '100%'}}>
            <MapContainer
                className="markercluster-map"
                center={[latitude, longitude]}
                zoom={zoom || DEFAULT_MAP_ZOOM}
                maxZoom={18} >

                {showEmptyMap && <div className="map-warning">
                    {gettext('Nothing to display, ')}
                    <a href="#" onClick={toggleMap}>{gettext('return to list view')}</a>
                </div>}
                {showWarning && <div className="map-warning">
                    {gettext('Not all available locations are displayed on the map')}
                </div>}

                <GoogleLayer googlekey={settings.GOOGLE_API_KEY} maptype="ROADMAP"/>
                {(cars.length > 1) /* see https://github.com/YUzhva/react-leaflet-markercluster/issues/65 */
                    ? (<MarkerClusterGroup showCoverageOnHover={false}>
                        {this.renderMarkers(cars)}
                    </MarkerClusterGroup>)
                    : this.renderMarkers(cars)}
            </MapContainer>
            <div className="map-view__hotel-counter">
                {total} {gettext('vendor locations')}
            </div>
            {selected !== null && this.renderSelected(carRates, selected)}

        </div>);
    }

    renderLoading() {
        return (<div>{gettext('Loading')}</div>);
    }

    render() {
        const {ready} = this.props;
        return ready ? this.renderMap() : this.renderLoading();
    }
}

@connect((state) => {
    return {hasFilters: !isEmpty(state.cars.filters)};
}, {onClear: clearFilters, filterRates})
export default class CarsMap extends React.Component {
    static propTypes = {
        hasFilters: PropTypes.bool,
        onClear: PropTypes.func.isRequired,
        toggleMap: PropTypes.func.isRequired,
    };

    render() {
        const {hasFilters, toggleMap} = this.props;

        return (<div className="map-view__wrapper">
            <div className="map-view__control">
                <Button bsStyle="link" className="map-view__control-lnk" onClick={toggleMap}>
                    <span className="glyphicons glyphicons-remove-2" />
                    &nbsp;
                    {gettext('Close map')}
                </Button>
            </div>
            <div className="map-view__sidebar">
                {settings.SUB_PARTNER === 'aft' && (<MultiSearchContext />)}
                <div className="section-wrapper">
                    <div className="map-view__clear-filters">
                        {hasFilters && (<Button bsStyle="link" className="btn-link--narrow" onClick={this.props.onClear}>
                           &times; {gettext('Clear all filters')}
                        </Button>)}
                    </div>
                    <Filters />
                </div>
            </div>
            <div className="map-view__map-wrapper">
                <QuerySize>
                    <MapView {...this.props} />
                </QuerySize>
            </div>
        </div>);
    }
}
