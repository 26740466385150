import gettext from 'airborne/gettext';
import React from 'react';
import PropTypes from 'prop-types';

import {format as formatPrice} from 'airborne/utils/price';


export default class HotelTooltip extends React.Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        children: PropTypes.any,
        available: PropTypes.shape({
            'is_available': PropTypes.bool,
            'min_avg_rate': PropTypes.number,
            'currency': PropTypes.string,
        }),
    };

    render() {
        const {name, available} = this.props;
        const {
            'is_available': isAvailable,
            'min_avg_rate': min,
            'currency': currency,
        } = available || {};
        return (<div>
            <div className="map-view__tooltip__title">
                {name}
            </div>
            {isAvailable && (<div className="map-view__tooltip__price">
                <strong>{formatPrice(min, currency)}</strong>
                &nbsp;
                <span className="small">{gettext('avg. / night')}</span>
            </div>)}
        </div>);
    }
}
